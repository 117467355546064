import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approvalStyle"
  }, [_c("a-table", {
    staticStyle: {
      "margin-top": "24px",
      background: "#fff"
    },
    attrs: {
      loading: _vm.loading,
      pagination: false,
      columns: _vm.columns,
      dataSource: _vm.dataSource,
      customRow: _vm.customRow,
      rowKey: function rowKey(it, i) {
        return it.id;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "categoryname" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.parentcategoryname + "-" + record.syscategoryname || "---") + " ")])]) : key == "parentcategoryname" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.parentcategoryname + "-" + (record.categoryname || record.syscategoryname || "---")) + " ")])]) : key == "productnum" ? _c("span", [record.productnum || record.productids ? _c("span", [_vm._v(" " + _vm._s(record.productnum || record.productids && record.productids.length) + " ")]) : _c("span", [_vm._v("---")])]) : key == "id" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.id || record.productid || "---") + " ")])]) : key == "iosimgurl" ? _c("span", [_c("div", [record.iosimgurl ? _c("img", {
            staticClass: "like_img",
            attrs: {
              src: _vm.splitImg(record.iosimgurl)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record.iosimgurl));
              }
            }
          }) : _c("span", [_vm._v(" --- ")]), _c("br")])]) : key == "ios2imgurl" ? _c("span", [_c("div", [record.ios2imgurl ? _c("img", {
            staticClass: "like_img",
            attrs: {
              src: _vm.splitImg(record.ios2imgurl)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record.ios2imgurl));
              }
            }
          }) : _c("span", [_vm._v(" --- ")]), _c("br")])]) : key == "androidimgurl" ? _c("span", [_c("div", [record.androidimgurl ? _c("img", {
            staticClass: "like_img",
            attrs: {
              src: _vm.splitImg(record.androidimgurl)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record.androidimgurl));
              }
            }
          }) : _c("span", [_vm._v(" --- ")]), _c("br")])]) : key == "videourl" ? _c("span", [_c("div", [record.videourl ? _c("div", {
            staticClass: "like_video"
          }, [_c("img", {
            staticClass: "play_img",
            attrs: {
              src: require("@/assets/svgs/play.svg"),
              alt: ""
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.videourl, "video");
              }
            }
          })]) : _c("span", [_vm._v("---")])])]) : key == "isskip" ? _c("span", [_vm._v(" " + _vm._s(record.isskip ? "否" : "是") + " ")]) : key == "imgurl" ? _c("span", [_c("div", [record.imgurl ? _c("img", {
            staticClass: "like_img",
            attrs: {
              src: _vm.splitImg(record.imgurl)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record.imgurl));
              }
            }
          }) : _c("span", [_vm._v(" --- ")]), _c("br")])]) : key == "imgurls" ? _c("span", [_c("div", [record.imgurls || record.imgurl ? _c("img", {
            staticClass: "like_img",
            attrs: {
              src: _vm.splitImg(record.imgurls || record.imgurl)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record.imgurls || record.imgurl));
              }
            }
          }) : _c("span", [_vm._v(" --- ")]), _c("br")])]) : key == "type" ? _c("span", [_vm._v(" " + _vm._s(_vm.typeList[record.type - 1].name) + " ")]) : key == "operation" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["youmaylike:btn:delete"],
              expression: "['youmaylike:btn:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id, 0);
              }
            }
          }, [_vm._v("删除")])]) : key == "begintime" ? _c("span", [_c("span", [_vm._v(_vm._s("".concat(record.begintime, "\u2014").concat(record.endtime)))])]) : key == "action" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["pagehome:btn:detail"],
              expression: "['pagehome:btn:detail']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleDetail(record);
              }
            }
          }, [_vm._v("查看")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["pagehome:btn:edit"],
              expression: "['pagehome:btn:edit']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.editorClick(record);
              }
            }
          }, [_vm._v("修改")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["pagehome:btn:del"],
              expression: "['pagehome:btn:del']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.pageDelete(record);
              }
            }
          }, [_vm._v("删除")])], 1)]) : key == "actions" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["hotWord:btn:detail"],
              expression: "['hotWord:btn:detail']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleInfo(record);
              }
            }
          }, [_vm._v("查看")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["hotWord:btn:edit"],
              expression: "['hotWord:btn:edit']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.editor(record);
              }
            }
          }, [_vm._v("修改")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["hotWord:btn:del"],
              expression: "['hotWord:btn:del']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.Delete(record);
              }
            }
          }, [_vm._v("删除")])], 1)]) : key == "banneraction" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["banneraction:btn:edit"],
              expression: "['banneraction:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.bannerEdit(record);
              }
            }
          }, [_vm._v("修改")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["banneraction:btn:del"],
              expression: "['banneraction:btn:del']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.bannerDelete(record);
              }
            }
          }, [_vm._v("删除")])], 1)]) : key == "splashaction" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["splashaction:btn:edit"],
              expression: "['splashaction:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.splashEdit(record);
              }
            }
          }, [_vm._v("修改")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["splashaction:btn:del"],
              expression: "['splashaction:btn:del']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record);
              }
            }
          }, [_vm._v("删除")])], 1)]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("PreviewMedia", {
    attrs: {
      isShow: _vm.previewVisible && ["video", "audio"].includes(_vm.previewType),
      previewSource: _vm.previewImage,
      mediaType: "video"
    },
    on: {
      closePreview: _vm.previewHandleCancel
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible && _vm.previewType == "pic",
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };